import React from 'react';
import { makeStyles, Box, useMediaQuery, DialogContent, DialogActions } from '@material-ui/core';
import { theme as globalTheme } from '../testUtils';
import Dialog from '../common/Dialog.component'
import ActionButtons from '../common/ActionButtons.component'
import Text from '../common/text.component'

const InfoText = "Tu cuenta tiene una sesión abierta en otro navegador."
const ContinueText = `Si presionás "CONTINUAR", se cerrará automáticamente la sesión abierta.`

const useStyles = makeStyles(theme => ({
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      width: '26rem'
    },
  },
  title: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionButtonsDesktop: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  actionButtonsMobile: {
    display: "flex",
    minWidth: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dialogActionsMobile: {
    width: "100%"
  },
  dialogBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  }
}));

const CurrentSessionDialog = (
  {
    open,
    onClose,
    onConfirm
  }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <Content onConfirm={onConfirm} onClose={onClose} fullScreen={fullScreen} />
      </DialogContent>
    </Dialog>
  );
};


const Content = ({
  onConfirm,
  onClose,
  fullScreen,
}) => {
  const classes = useStyles();
  const actionButtonsProps = {
    onConfirm,
    onCancel: onClose,
    confirmText: "Continuar"
  }

  return (
    <Box className={classes.dialogBox}>
      <Text className={classes.title} medium bold>{InfoText}</Text>
      <Text className={classes.title} little light>{ContinueText}</Text>
      {fullScreen ?
        <DialogActions
          className={classes.dialogActionsMobile}
        >
          <Box className={classes.actionButtonsMobile}>
            <ActionButtons {...actionButtonsProps}/>
          </Box>
        </DialogActions> 
        :
        <Box className={classes.actionButtonsDesktop}>
          <ActionButtons {...actionButtonsProps}/>
        </Box>
      }
    </Box>
  );
};

export default CurrentSessionDialog;

import React, { useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { LOGIN } from '../../mutations';
import LoginForm from '../../components/login-form/LoginForm.component';
import LoadingModal from '../../components/common/LoadingModal.component';
import CurrentSessionDialog from '../../components/current-session-dialog/CurrentSessionDialog.component';
import { isAdmin, login, USER_ROLES } from '../../api/authorization';
import { validateEmail, validatePassword } from '../../utils/validators.utils';
import { useAppContext } from '../../context/AppContext';
import { TITLE_REGISTER_INACTIVE } from '../../components/register-particular/ActivationForm.component';
import setRegisterData from '../../context/user/actions/setRegisterData';
import ActivationFormContainer from '../register-particular-form/ActivationForm.container';
import { navigate } from 'gatsby';
import { navigationRoutes } from '../../api/navigationRoutes.enum';
import { DELETE_CREDIT_LINE, SET_CLEAR_USED_CAR } from '../../context/budget/constants';
const ActiveSessionMessage = 'Already active session';
const LOGIN_ERROR_TXT = 'Los datos no son válidos. Intentá de nuevo.';
const USER_INACTIVE_MESSAGE = 'Inactive user';
const SUBTITLE_REGISTER_INACTIVE = 'Activá tu cuenta con el código que te enviamos a:';

const LoginFormContainer = () => {
  const { user, budget } = useAppContext();
  const [showDialog, setShowDialog] = useState(false);
  const [loginInputValues, setLoginInputValues] = useState(null);
  const [errorMessages, setErrorMessages] = useState(null);
  const [showActivationForm, setShowActivationForm] = useState(false);
  const { userDispatch, budgetDispatch } = useAppContext();
  const client = useApolloClient();

  const [loginMutation, { loading, error, called }] = useMutation(LOGIN, {
    onCompleted: ({ login: loginData }) => {
      if(isVisibleCar(loginData)){
        clearBudgetContext();
        navigate(navigationRoutes.CATALOGO);
      }else{
        if(isAdmin(loginData)){
          clearBudgetContext();
        }
        login(loginData, client, user.prevRoute);
      }
    },
    onError: (error) => {
      if(error.message.includes(ActiveSessionMessage)){
        setShowDialog(true)
      }
      else if (error.message.includes(USER_INACTIVE_MESSAGE)) {
        setShowActivationForm(true);
      }
      
    },
  });
  const validateEmptyFields = (fields) => {
    const validEmail = validateEmail(
      fields.email,
      '',
      'Escribí tu e-mail para ingresar.'
    );

    const validPassword = validatePassword(
      fields.password,
      LOGIN_ERROR_TXT,
      'Escribí tu contraseña para ingresar.'
    );

    const valid = validEmail && validPassword;
    const errors = { email: validEmail, password: validPassword };

    return { valid, errors };
  };

  const validateForm = (newFields) => {
    const { valid, errors } = validateEmptyFields(newFields);
    setErrorMessages(errors);
    return valid;
  };

  const isVisibleCar = (loginData) => {
    //Verifica que el auto que se esta presupuestando sea visible para el rol del usuario
    let valid = false;
    try {
      const userRol = loginData.profile.role_name;
      const selectedStock = budget?.car?.stock.filter(stock => stock.id === budget?.car?.selectedStock);
      valid = userRol === USER_ROLES.RESALE
        && !selectedStock[0]?.stateReseller
        && selectedStock[0]?.statePublic;
      return valid;
    } catch (error) {
      return valid;
    }
  };

  const clearBudgetContext = () => {
    budgetDispatch({ type: DELETE_CREDIT_LINE });
    budgetDispatch({ type: SET_CLEAR_USED_CAR });
  }

  const handleLogin = (loginInput) => {
    setLoginInputValues(loginInput);
    const isValid = validateForm(loginInput);
    
    if (isValid) {
      setRegisterData(userDispatch, { ...loginInput, password: undefined });
      loginMutation({ variables: { loginInput } });
    }
  };

  const handleLoginAndSession = (closeSession) => {
    toggleShowDialog();
    const loginInput = loginInputValues;
    loginInput.closeOtherSessions = closeSession;
    handleLogin(loginInput);
  };

  const toggleShowDialog = () => {
    setShowDialog(!showDialog);
  };
  return (
    <>
      <LoadingModal open={loading} />
      <CurrentSessionDialog
        open={showDialog}
        onClose={() => handleLoginAndSession(false)}
        onConfirm={() => handleLoginAndSession(true)}
      />
      {showActivationForm ? (
        <ActivationFormContainer title={TITLE_REGISTER_INACTIVE} subTitle={SUBTITLE_REGISTER_INACTIVE} resendCode />
      ) : (
        <LoginForm
          onLogin={handleLogin}
          errorMessages={errorMessages}
          LOGIN_ERROR_TXT={LOGIN_ERROR_TXT}
          loginError={called && !loading && error !== undefined}
        />
      )}
    </>
  );
};

export default LoginFormContainer;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import HiddenOn from '../common/HiddenOn.component';
import Link from '../common/Link.component';
import Logo from '../common/logo.component';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { NEW_PALETTE } from '../../assets/theme';

const backButtonWidth = '3rem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main,
    position: 'relative',
  },
  logoContainer: {
    flexGrow:5,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    paddingRight: backButtonWidth
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display:'flex',
    [theme.breakpoints.up('lg')] : {
      flexGrow: 1,
      width: 'auto',
      height: '3.75rem',
    },
    [theme.breakpoints.down('md')] : {
      width: 'auto',
      height: '48px',
    },
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    display:'flex',
    flexDirection:'row',
    height: '64px',
  },
  backButton: {
    marginLeft: '0',
    '& svg': {
      width: '19px',
      height: '16px',
      color: NEW_PALETTE.neutral.gray07,
    },
    '& svg path': {
      strokeWidth: 3,
    },
  },
}));

const BackAppBar = ({ onBack }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="absolute"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <HiddenOn desktop>
          <IconButton
            color="inherit"
            edge="start"
            className={classes.backButton}
            onClick={() => onBack()}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </HiddenOn>
        <Box className={classes.logoContainer}>
          <Link to='/' className={classes.logo}>
            <Logo className={classes.logo}/>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};


export default BackAppBar;


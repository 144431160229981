import React from 'react';
import Layout from '../components/layouts/layout.component';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import LoginFormContainer from '../containers/login-form/LoginForm.container';
import backgroundBanner from '../assets/images/onboarding_layoutLogin.png';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '1rem',
    marginBottom: '2px',
    marginRight: '-2px',
  },
  container: {
    paddingTop: theme.spacing(16),
    [theme.breakpoints.down('md')]:{
      paddingTop: theme.spacing(10),
    },
    display:'flex',
    alignContent:'center',
  },
  link: {
    height: '1rem',
    margin: '0.8rem auto 0 0.8rem',
  },
  banner: {
    background: `url(${backgroundBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'center',
    borderRadius: '0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const PAGE_TITLE = 'Login - Carmuv';
const META_DESCRIPTION = 'Ingresá a Carmuv con tu usuario y clave.';
const IS_LOGIN_PAGE = true
const IngresarPage = () => {
  const classes = useStyles();

  return (
    <Layout title={PAGE_TITLE} description={META_DESCRIPTION} isLoginPage={IS_LOGIN_PAGE}>
      <Box display="flex" flex="1 0 50%" width="100%" alignContent="center">
    <Box className={classes.banner} width="100%">
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        overflow="auto"
      >
      <Box className={classes.container}>
        <LoginFormContainer />
      </Box>
      </Box>
      </Box>
    </Layout>
  );
};

export default IngresarPage;

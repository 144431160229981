import React, { useEffect, useRef } from 'react'
import { Box, Link, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Text from '../common/text.component'
import PropTypes from 'prop-types'
import { useAppContext } from '../../context/AppContext'
import {
  FIELDS_INITIAL_VALUE,
  MESSAGE_EXPIRED_CODE,
} from '../../containers/register-particular-form/constants'
import Loading from '../common/Loading.component'
import clsx from 'clsx'
import TextField from '../common/TextField.component'
const SUBTITLE_REGISTER =
'Ahora activá tu cuenta con el código que te enviamos a:'
const EMAIL_SPAM_MESSAGE =
'Si no ves el e-mail con el código en tu bandeja de entrada, revisá la carpeta de spam o correo no deseado.'
const EMAIL_CONFIRMATION_MESSAGE = '¿No recibiste el e-mail?'
const MESSAGE_SEND_CODE = 'Enviar código'
const MESSAGE_RESEND_CODE = 'Reenviar'
const KEY_CODE_BACKSPACE = 8
export const TITLE_REGISTER = '¡Gracias por sumarte!'
export const TITLE_REGISTER_INACTIVE = 'Aún no se activó esta cuenta'
const useStyle = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '25rem',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.space.horizontal.mobile}`,
    },
  },
  title: {
    marginBottom: '0.5rem',
  },
  subtitle: {
    marginBottom: '0.5rem',
  },
  email: {
    marginBottom: '3.5rem',
    wordBreak: 'break-all'
  },
  registerForm: {
    marginBottom: '3.5rem',
    position: 'relative',
  },
  confirmationMessage: {
    marginTop: '0.75rem',
  },
  link: {
    marginLeft: '0.625rem',
    color: theme.palette.primary.main2,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.6875rem',
    marginLeft: '1.875rem',
    marginTop: '0.25rem',
  },
  divLoading: {
    opacity: '0.5',
  },
  loading: {
    position: 'absolute',
    top: '0',
  },
  borderFocus: {
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.main2} !important`,
    },
  },
  borderWithExpiredCode: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grayScale.g200,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: `${theme.palette.grayScale.g200} !important`,
        },
      },
    },
  },
  textInput: {
    marginTop: 0,
    marginLeft: '0.625rem',
    width: '4.2188rem',
    '& input': {
      height: '2rem',
      textAlign: 'center',
      fontWeight: theme.typography.weight.semibolder,
    },
    '& label': {
      position: 'absolute',
      top: '50%',
      left: ' 1.25rem',
      transform: 'translate(0, -50%)',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.grayScale.g200,
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.grayScale.g400,
        },
      },
      '&.Mui-error': {
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.error.main,
          },
        },
      },
      '&.Mui-error.Mui-focused': {
        '& fieldset': {
          border: `1px solid ${theme.palette.error.main}`,
        },
      },
    },
  },
}))

const ErrorMessage = ({ text }) => {
  const classes = useStyle()
  return <Box className={classes.errorMessage}>{text}</Box>
}

const ActivationForm = ({
  validateFields = () => {},
  getValidationCode = () => {},
  resendUserCode = () => {},
  loading = false,
  errorMessage = '',
  setFields = () => {},
  fields = FIELDS_INITIAL_VALUE,
  isFocused = () => {},
  focus = false,
  title = '',
  subTitle = SUBTITLE_REGISTER
}) => {
  const classes = useStyle()
  const {
    user: { user },
  } = useAppContext()
  const character1 = useRef()
  const character2 = useRef()
  const character3 = useRef()
  const character4 = useRef()
  useEffect(() => {
    character1.current.focus()
  }, [])

  const handleOnSubmit = (newFields) => {
    const code = Object.values(newFields).join('')
    getValidationCode({ user: user.email, code })
    character1.current.focus()
  }

  const handleTextFieldChange = (key, value, nextInput) => {
    if (nextInput && value) {
      nextInput.current.focus()
    }
    const newFields = { ...fields }
    newFields[key] = value
    setFields(newFields)
    validateFields(newFields) && handleOnSubmit(newFields)
  }

  const handleResendCode = () => {
    resendUserCode({
      variables: { email: user.email },
    })
  }

  const handleKeyDown = (e, prevInput, inputName, nextInput) => {
    if (e.keyCode === KEY_CODE_BACKSPACE) {
      if (inputName === 'character1') {
        handleTextFieldChange('character1', '')
        return
      }
      handleTextFieldChange(inputName, '')
      prevInput.current.focus()
    } 
    else if (e.target.value && nextInput) {
      nextInput.current.focus()
    }
  }
  const onFocus = () => isFocused(true)

  const onBlur = () => isFocused(false)

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={loading && classes.divLoading}
    >
      <Box className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="20px"
        >
          <Text className={classes.title} primary large semibolder disabled>
            {title}
          </Text>
          <Text className={classes.subtitle} little g600 center>
            {subTitle}
          </Text>
          <Text className={classes.email} small g500 semibolder center>
            {user.email}
          </Text>
          <Box className={classes.registerForm}>
            <SingleCodeInput
              inputRef={character1}
              value={fields.character1}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) =>
                e.target.value &&
                handleTextFieldChange('character1', e.target.value, character2)
              }
              onKeyDown={(e) =>
                handleKeyDown(e, null, 'character1', character2)
              }
              focus={focus}
              errorMessage={errorMessage}
            />
            <SingleCodeInput
              inputRef={character2}
              value={fields.character2}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) =>
                e.target.value &&
                handleTextFieldChange('character2', e.target.value, character3)
              }
              onKeyDown={(e) =>
                handleKeyDown(e, character1, 'character2', character3)
              }
              focus={focus}
              errorMessage={errorMessage}
            />
            <SingleCodeInput
              inputRef={character3}
              value={fields.character3}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) =>
                e.target.value &&
                handleTextFieldChange('character3', e.target.value, character4)
              }
              onKeyDown={(e) =>
                handleKeyDown(e, character2, 'character3', character4)
              }
              focus={focus}
              errorMessage={errorMessage}
            />
            <SingleCodeInput
              inputRef={character4}
              value={fields.character4}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) =>
                handleTextFieldChange('character4', e.target.value, null)
              }
              onKeyDown={(e) => handleKeyDown(e, character3, 'character4')}
              focus={focus}
              errorMessage={errorMessage}
            />
            {loading && <Loading className={classes.loading} />}
            {errorMessage && <ErrorMessage text={errorMessage} />}
          </Box>
          {errorMessage === MESSAGE_EXPIRED_CODE ? (
            <>
              <Link
                href="#"
                className={classes.link}
                onClick={handleResendCode}
              >
                {MESSAGE_SEND_CODE}
              </Link>
            </>
          ) : (
            <>
              <Text className={classes.subtitle} little g600 center>
                {EMAIL_SPAM_MESSAGE}
              </Text>
              <Box>
                <Text small g600 center className={classes.confirmationMessage}>
                  {EMAIL_CONFIRMATION_MESSAGE}
                </Text>
                <Link
                  href="#"
                  className={classes.link}
                  onClick={handleResendCode}
                >
                  {MESSAGE_RESEND_CODE}
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const SingleCodeInput = ({ errorMessage, onChange, focus, ...props }) => {
  const classes = useStyle()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOnChange = (e) => {
    const value = e.target.value
    // si es un número y es positivo
    if (!isNaN(value) && +value >= 0) {
      onChange(e)
    }
  }
  return (
    <TextField
      className={clsx(classes.textInput, {
        [classes.borderFocus]: (isMobile || focus) && !errorMessage,
        [classes.borderWithExpiredCode]: errorMessage === MESSAGE_EXPIRED_CODE,
      })}
      variant="outlined"
      inputProps={{ maxLength: 1 }}
      disabled={errorMessage === MESSAGE_EXPIRED_CODE}
      error={errorMessage}
      onChange={handleOnChange}
      {...props}
    />
  )
}

ActivationForm.propTypes = {
  validateFields: PropTypes.func,
  getValidationCode: PropTypes.func,
  loading: PropTypes.bool,
  errorMessage: PropTypes.node,
}

export default ActivationForm

import React from 'react';
import { makeStyles,
  Dialog as MuiDialog } from '@material-ui/core';
import BackAppBar from '../header/BackAppBar.component';


const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      width: '26rem'
    }
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.borderRadius.main,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '20px',
    }
  }
}));

const Dialog = (
  {
    open,
    onClose,
    fullScreen,
    children,
    classDialog,
    ...restProps
  }) => {
  const classes = useStyles();

  return (
    <MuiDialog
      PaperProps={{
        className: classes.paper
      }}
      fullScreen={fullScreen}
      scroll="paper"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      className={classDialog}
      {...restProps}
    >
      { fullScreen &&
        <BackAppBar onBack={onClose}/>
      }
      {children}
    </MuiDialog>
  );
};

export default Dialog;

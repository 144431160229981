import React, { useEffect, useRef, useState } from 'react'
import { validateMinLength } from '../../utils/validators.utils'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import ActivationForm, { TITLE_REGISTER_INACTIVE } from '../../components/register-particular/ActivationForm.component'
import { GET_VALIDATION_CODE } from '../../queries/getValidationCode'
import { RESEND_USER_CODE } from '../../mutations/resendUserCode'
import { navigationRoutes } from '../../api/navigationRoutes.enum'
import { navigate } from 'gatsby'
import { LOGIN } from '../../mutations'
import { login } from '../../api/authorization'
import { useAppContext } from '../../context/AppContext'
import { FIELDS_INITIAL_VALUE, MESSAGE_EXPIRED_CODE } from './constants'
const MESSAGE_INVALID_CODE = 'El código no es válido. Intentá de nuevo.'

const ActivationFormContainer = ({ title, subTitle, resendCode }) => {
  const {user:{user, prevRoute}} = useAppContext()
  const [submitError, setSubmitError] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [fields, setFields] = useState(FIELDS_INITIAL_VALUE)
  const [focus, setFocus] = useState(false)
  const client = useApolloClient();
  useEffect(() => {
    if(resendCode){
      resendUserCode({
        variables: { email: user.email },
      })
    }
  }, [])
  
  const [loginMutation, { loading: loadingLogin }] = useMutation(LOGIN, {
    onCompleted: ({ login:loginData }) => {
      login(loginData, client, prevRoute);
    },
  });
  const { loading: loadingValidationCode, refetch } = useQuery(GET_VALIDATION_CODE, {
    onCompleted: async (data) => {
      const expiredCode = data.getValidationCode.expired
      const invalidCode = data.getValidationCode.invalid
      const successResponse = data.getValidationCode.success
      // Sí no es la primera carga (error) o la respuesta es exitosa
      if (expiredCode !== null || successResponse) {
        if (invalidCode) {
          setFocus(false)
          setErrorMessage(MESSAGE_INVALID_CODE)
          setFields(FIELDS_INITIAL_VALUE)
          return
        }
        if (expiredCode) {
          setFocus(false)
          setErrorMessage(MESSAGE_EXPIRED_CODE)
          return
        }
        successResponse && setErrorMessage('')
        await loginMutation( { variables: { loginInput: {email:user.email, password: user.password }}});
        navigate(prevRoute)
      }
    },
    notifyOnNetworkStatusChange: true,
  })
  const [resendUserCode, { loading: resendCodeLoading }] =
  useMutation(RESEND_USER_CODE, {
    onCompleted: () => {
      setErrorMessage('')
      setFields(FIELDS_INITIAL_VALUE)
    }
  })
  
  const getValidationCode = (newVariables) => {
    refetch(newVariables)
  }
  const validateFields = (fields) => {
    if (submitError) {
      setSubmitError('')
    }
    const isValidCharacter1 = validateMinLength(fields.character1, 1)
    const isValidCharacter2 = validateMinLength(fields.character2, 1)
    const isValidCharacter3 = validateMinLength(fields.character3, 1)
    const isValidCharacter4 = validateMinLength(fields.character4, 1)
    
    const valid =
    isValidCharacter1.valid &&
    isValidCharacter2.valid &&
    isValidCharacter3.valid &&
    isValidCharacter4.valid
    
    return valid
  }
  
  return (
    <>
      <ActivationForm
        validateFields={validateFields}
        submitError={submitError}
        getValidationCode={getValidationCode}
        loading={loadingValidationCode || resendCodeLoading || loadingLogin}
        errorMessage={errorMessage}
        resendUserCode={resendUserCode}
        fields={fields}
        setFields={setFields}
        isFocused={setFocus}
        focus={focus}
        title={title}
        subTitle={subTitle}
      />
    </>
  )
}

export default ActivationFormContainer

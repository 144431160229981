import React from 'react';
import Box from '@material-ui/core/Box';
import Button from './button.component';

const CANCEL_BTN_TEXT = 'Cancelar';
const CONFIRM_BTN_TEXT = 'Confirmar';

const ActionButtons = ({
  cancelText = CANCEL_BTN_TEXT,
  confirmText = CONFIRM_BTN_TEXT,
  onCancel = () => {},
  onConfirm  = () => {},
  disableConfirmButton = false,
  boxProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...boxProps}>
      <Button
        data-test-id="action-button-cancel"
        onClick={() => onCancel()}
        outline
      >
        {cancelText}
      </Button>
      <Button
        primary
        disabled={disableConfirmButton}
        data-test-id="action-button-save"
        onClick={() => onConfirm()}>
        {confirmText}
      </Button>
    </Box> );
};

export default ActionButtons;

import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TextField from '../common/TextField.component';
import Button from '../common/button.component';
import PasswordTextField from '../common/PasswordTextField.component';
import Text from '../common/text.component';
import Link from '../common/Link.component';
import clsx from 'clsx';
import { goToRegistro, goToRegistroParticular } from '../../api/navigation';

const TITLE_LOGIN = 'Ingresar a mi cuenta';
const REGISTER_TEXT = '¿No tenés una cuenta? Sumate a Carmuv';
const EMAIL_LABEL = 'E-mail';
const PASSWORD_LABEL = 'Contraseña';
const LOGIN_BTN_TXT = 'Ingresar';
const REGISTER_BTN_TXT = 'Crear mi cuenta';
const REGISTER_COMMERCIAL_TEXT = '¿Necesitás una cuenta comercial?';
const REGISTER_COMMERCIAL_BTN_TXT = 'Crear mi cuenta comercial';

const useStyle = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
      [theme.breakpoints.up('md')]:{
        width:'57%'
      },
      overFlow: 'hidden',
      '& .MuiFormControl-root':{
        marginTop:'1.875rem'
      },
      [theme.breakpoints.down('md')]:{
        maxWidth:'100%',
      },
    },
    textInput: {
      width:'80%',
      lineHeight:'21px',
      font:'14px',
      '& .MuiInputBase-input':{
        height:'2.125rem'
      },
        '& .MuiFormLabel-root': {
            color: `${theme.palette.grayScale.g700} !important`,
        },
      '& .MuiInputLabel-outlined': {
          transform: 'translate(0.875rem, 1.05rem) scale(1) !important',
        },

      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(0.875rem, -0.875rem) scale(0.75) !important',
      },
    },
    compactInput: {
      [theme.breakpoints.up('md')]:{
        width:'95%'
      }
    },
    title:{
      color: theme.palette.secondary.main,
      fontWeight: '600',
      fontSize:'1.25rem',
      lineHeight:'1.875rem',
      marginBottom: '1.875rem',
    },
    logo: {
      paddingBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(3),
      width:'80%'
    },
    buttonCommercial:{
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('md')]:{
        marginTop: 0,
        fontWeight:'500'
      }
    },
    singUpbtn: {
      [theme.breakpoints.up('md')]:{
        maxWidth:'50%',
        marginRight:'auto',
        marginLeft:'auto'
      },
      [theme.breakpoints.down('md')]:{
        width:'65%'
      }
    },
    horizontalLine:{
      marginTop:'2rem',
      width:'50%',
      [theme.breakpoints.down('md')]:{
        width:'80%'
      }
    },
    recoverPassword:
    {
      marginTop: '1.75rem',
      fontSize:'1rem'
    },
    forgotLink: {
      marginLeft:'0.625rem',
      marginTop: theme.spacing(1), 
      [theme.breakpoints.down('md')]:{
        fontWeight:'500',
      }
    },
    registerText:{
      [theme.breakpoints.down('md')]:{
        width:'80%'
      }
    }
  })
);


const FIELDS_INITIAL_VALUE = {
  email: '',
  password: '',
};

const LoginForm = ({ onLogin, loginError, errorMessages, LOGIN_ERROR_TXT }) => {
  const classes = useStyle();
  const [fields, setFields] = useState(FIELDS_INITIAL_VALUE);
  const [error, setError] = useState(false);
  useEffect( () => {
    setError(loginError);
  }, [loginError] );

  const errorEmail = errorMessages && !errorMessages.email.valid || error
  const errorMessageEmail = errorMessages && !fields.email && errorMessages.email.error
  const errorPassword = errorMessages && !errorMessages.password.valid || error
  const errorMessagePassword = errorMessages && !fields.email && errorMessages.password.error || error && LOGIN_ERROR_TXT

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    const NOT_ALLOW_SPACE_REGEX = /^\S*$/;
    if (!NOT_ALLOW_SPACE_REGEX.test(value)) { 
      return
    }
    setFields(newFields);
  };
  
  function handleSubmit(e) {
    e.preventDefault();
    onLogin(fields);
  }

  const handleRegister = () => {
    goToRegistroParticular()
  }
  
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Box className={classes.container}>
        <Text className={classes.title}>{TITLE_LOGIN}</Text>
        <form onSubmit={handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            boxSizing="border-box"
            justifyContent="center"
          >
            <TextField
              error={errorEmail}
              helperText={errorMessageEmail}
              className={classes.textInput}
              data-test-id="input-login-email"
              label={EMAIL_LABEL}
              value={fields.email}
              onChange={(evt) => handleTextFieldChange('email' ,evt.target.value)}
              variant="outlined"
            />
            <PasswordTextField
              error={errorPassword}
              helperText={errorMessagePassword}
              className={classes.textInput}
              data-test-id="input-login-password"
              label={PASSWORD_LABEL}
              value={fields.password}
              onChange={(evt) => handleTextFieldChange('password' ,evt.target.value)}
              variant="outlined"
            />
            <Button
              primary
              className={classes.button}
              type="submit"
              fullWidth
            >
              {LOGIN_BTN_TXT}
            </Button>
            <Box className={classes.recoverPassword}>
              <Text>
                ¿Olvidaste la contraseña?
              </Text>
              <Link inverted className={classes.forgotLink} to='/recuperar-contrasenia/'>
                Recuperar
              </Link>
            </Box>
          </Box>
        </form>
        <Box marginTop="2.75rem" textAlign="center" className={classes.registerText}>
          <Text g600 medium display='block'>
            {REGISTER_TEXT}
          </Text>
        </Box>
        <Button
          outline
          className={clsx(classes.button, classes.singUpbtn)}
          variant="outlined"
          type="submit"
          fullWidth
          onClick={handleRegister}
        >
          {REGISTER_BTN_TXT}
        </Button>
      </Box>
      <hr className={classes.horizontalLine}></hr>
      <Box>
      <Box marginTop="0.875rem" textAlign="center">
          <Text primary small display='block'>
            {REGISTER_COMMERCIAL_TEXT}
          </Text>
        </Box>
        <Button
          text
          className={clsx(classes.buttonCommercial)}
          type="submit"
          fullWidth
          onClick={goToRegistro}
        >
          {REGISTER_COMMERCIAL_BTN_TXT}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;

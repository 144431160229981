import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField as MuiTextField, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles( theme => ({
  label: {
    color: theme.palette.grayScale.g400,
  },
  outlinedMargin: {
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: theme.palette.grayScale.g300,
    },
  },
  textfield: {
    '& legend > span': {
      display: 'none',
    },
  },
  mediumSize: {
    '&.MuiInputBase-input':{
      height:'2.125rem'
    },
  },
  startAdornment: {
    '& p': {
      padding: 0,
      marginLeft: '.3rem',
    },
  },
}));

const TextField = ({ className, type, prefix, ...props }) => {
  const classes = useStyles();
  const isOutlined = !!props.variant && props.variant === 'outlined';

  return (
    <MuiTextField
      {...props}
      className={clsx(classes.textfield, className, classes.mediumSize)}
      size="medium"
      height="50px"
      InputLabelProps={{
        classes: {
          root: clsx(classes.label, { [classes.outlinedMargin]: isOutlined }),
        },
        ...props.InputLabelProps
      }}
      InputProps={prefix ? {
        startAdornment: <InputAdornment className={classes.startAdornment} position="start">{prefix}</InputAdornment>,
      } : props?.InputProps }
      type={type === 'password' ? 'password' : 'text'}
    />
  );
};

TextField.propTypes = {
  className: PropTypes.node,
};

export default TextField;


import React from 'react';
import { baseTheme } from '../assets/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import '../assets/style.css';

export const theme = createTheme(baseTheme);

export const WithMateriaUITheme = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export const findByTestId = (wrapper, testId) => {
    return wrapper.find(`[data-test-id="${testId}"]`)
}

